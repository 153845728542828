


.content--breadcrumb {
  font-size: 14px;
  font-size: .875rem;
  display: block;

  width: 100%;
  border-bottom: 1px solid #e1e5eb;
  &:after {
    content: "";
    display: table;
    clear: both;
  }
  .breadcrumb--list {
    overflow: hidden;
  }
  .breadcrumb--entry, .breadcrumb--separator {
    display: none;
    float: left;
    list-style: none;
    white-space: nowrap;
    overflow: hidden;
  }
  .breadcrumb--entry:last-child {
    display: list-item;
  }
  .breadcrumb--link {
    line-height: 24px;
    line-height: 1.5rem;
    padding: 10px 10px 10px 10px;
    padding: .625rem .625rem .625rem .625rem;
  }
  .breadcrumb--separator {
    line-height: 24px;
    line-height: 1.5rem;
    padding: 10px 10px 10px 10px;
    padding: .625rem .625rem .625rem .625rem;
    font-size: 9px;
    font-size: .5625rem;
  }
  .breadcrumb--link {
    display: block;
    color: #54565c;
    &:hover {
      color: #54565c;
    }
  }
  .is--active .breadcrumb--link {
    color: #54565c;
  }

  border-bottom: 0px solid #e1e5eb;
  &:after {
    content: "";
    display: table;
    clear: both;
  }
  .breadcrumb--list {
    overflow: hidden;
    height: 20px;
  }
  .breadcrumb--entry, .breadcrumb--separator {
    display: none;
    float: left;
    font-size: 12px;
    font-size: .75rem;
    list-style: none;
    white-space: nowrap;
    overflow: hidden;
    line-height: 20px;
    line-height: 1.25rem;
    padding: 25px 10px 25px 0px;
    padding: 1.5625rem .625rem 1.5625rem 0rem;
  }
  .breadcrumb--entry:last-child {
    display: list-item;
  }
  .breadcrumb--separator {
    color: #989ca4;
  }
  .breadcrumb--link {
    padding: 0px;
    padding: 0rem;
    line-height: 20px;
    line-height: 1.25rem;
    color: #54565c;
    display: none;
    &:hover {
      color: $secondary;
    }
  }
  .is--active .breadcrumb--link {
    font-weight: bold;
  }
}

@media screen and (min-width: 48em) {
  .content--breadcrumb {
    padding: 0px 20px 0px 20px;
    padding: 0rem 1.25rem 0rem 1.25rem;
    .breadcrumb--list {
      padding-left: 0;
    }
    .breadcrumb--entry, .breadcrumb--separator {
      display: list-item;
    }
  }
}

@media screen and (min-width: 78.75em) {
  .content--breadcrumb {
    padding: 0px 40px 0px 40px;
    padding: 0rem 2.5rem 0rem 2.5rem;
  }
}

@media screen and (min-width: 48em) {
  .content--breadcrumb {
    padding: 0px 20px 0px 20px;
    padding: 0rem 1.25rem 0rem 1.25rem;
    .breadcrumb--list {
      padding-left: 0;
      height: auto;
    }
    .breadcrumb--entry, .breadcrumb--separator {
      display: list-item;
    }
    .breadcrumb--link {
      display: block;
    }
  }
}

@media screen and (min-width: 78.75em) {
  .content--breadcrumb {
    padding: 0px 0px 0px 0px;
    padding: 0rem 0rem 0rem 0rem;
  }
}

::-moz-placeholder, ::-webkit-input-placeholder, :-ms-input-placeholder {
  color: #797b84;
}
