// Customizing bootstrap variables
$text-color: #2C2D2F;
/*$primary: #8DAA0B;
$secondary: #cddb47;
$info: #F0F4FA;
$success: $primary;
$enable-rounded: false;
$enable-shadows: true;
$enable-gradients: false;

$headings-color: #2C2D2F;


$font-size-base: 1rem;
$checkbox-font-size: 1.7rem !default;
$h1-font-size: $font-size-base * 2;
$h2-font-size: $font-size-base * 1.75;
$h3-font-size: $font-size-base * 1.50;
$h4-font-size: $font-size-base * 1.15;
$h5-font-size: $font-size-base;
$h6-font-size: $font-size-base;*/

// Import original bootstrap
@import "../../node_modules/bootstrap/scss/bootstrap";

$checkbox-fa-color: $primary;

@import "jquery.growl";
@import "dropzone";
@import "breadcrumbs";
@import "header";
@import "footer";
@import "topbar";
@import "checkbox-radio";
@import "typeahead";
@import "slider";
@import "timeline";
@import "slick";
@import "slick-theme";



@import "variables";
//@import "bootstrap/scss/bootstrap";
//@import "default";
@import "sequences";
@import "timeline";

body, html {
  margin-bottom: 120px;
}

/*********************
Box Sizing
*********************/

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body {
  box-sizing: border-box;
}

$checkbox-background: #fff;
$checkbox-border: #dddddd;
$checkbox-checked: #333;
$checkbox-background-checked: #daf7a8;

@mixin vh() {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

label {
  cursor: pointer;
  font-size: 1rem;
  line-height: 1rem;
}

input[type="checkbox"] {
  @include vh();
  &:focus {
    + label {
      &:before {
      }
    }
  }
  + label {
    //padding: 4px 6px 0 32px;
    padding: 0.5rem 0 0 2.2rem;
    margin-bottom: 1rem;
    user-select: none;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 1.75rem;
      height: 1.75rem;
      border: 2px solid $checkbox-border;
      border-radius: 0.25rem;
      background: $checkbox-background;
      text-align: center;
      transition: background 200ms ease-out;
    }
    &:after {
      content: "";
      //background-color: $checkbox-background;
      position: absolute;
      // top: 0.45rem;
      // left:  0.5rem;
      // width: 1rem;
      // height:  1rem;
      transform: scale(0);
      transition: transform 200ms ease-out;
    }
  }
}

input[type="checkbox"] {
  + label {
    &:after {
      background-color: transparent;
      content: "";
      display: block;
      position: absolute;
      left: 0.65rem;
      top: 0.25rem;
      // margin-left: 0;
      // margin-top: 0;
      width: 0.5rem;
      height: 1rem;
      opacity: 0.2;
      border-bottom: 3px solid $checkbox-checked;
      border-right: 3px solid $checkbox-checked;
      transform: rotate(45deg);
      transition: border-color 0.3s ease;
    }
  }
  &:checked {
    + label {
      text-decoration: line-through;
      color: #999;
      &:before {
        content: "";
        background: $checkbox-background-checked;
      }

      &:after {
        content: "";
        opacity: 1;
        border-bottom: 3px solid $checkbox-checked;
        border-right: 3px solid $checkbox-checked;
      }
    }
  }
}
.checkbox--row {
  display: block; position: relative
}

.done-by-label {
  &.btn {
    position: absolute;
    padding: 0.1rem 0.4rem;
    right: 0;
    top: -2px;
  }
  &.done--by--me {
    background-color: #0f6674;
  }
}

.btn {
  padding: .5rem .75rem;
}

.weekday--Sun,
.weekday--Sat {
  background-color: #fff2e0;
}

.btn-secondary {
  &:hover{
    background-color: #d8ed05;
    border-color: #d8ed05;
  }
}

.btn-outline-secondary {
  border: .125rem solid $secondary;
  color:#000;
}

.card-header-product h3 {
  cursor: pointer;

}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.login--form--container,
.register--form--container {
  margin: 2rem auto;
  display: block;
  border-radius: 4px;
  background: $gray-100;
  padding: 2rem;
}

.nav-tab {
  background-color: #eee;
  padding: .5rem .75rem;
  margin-right: 0.5rem;
  cursor: pointer;
  width: 48.5%;
  &.active{
    background-color: #8DAA0B;
    color: #fff;
  }
}

.left-info {
  position: absolute;
  left: 2%;
  bottom: -50rem;
  width: 27%;
  opacity: 0;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  .info-body {
    padding: 24px;
    background-color: #fde800;
  }
}

.btn-feature {
  color: #fff;
  background-color: #69923a;
  border-color: #fff;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #fde800;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: $text-color;
  text-align: center;
  background-color: #fde800;
}

.nav-left {
  position: absolute;
  width: 27%;
  left: 2%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.content-container {
  position: absolute;
  border-right: 0.5rem solid #C9DD03;
  width: 60%;
  left: 31%;
  height: 100%;

  &.hidden {
    bottom: -100rem;
    opacity: 0.1;
    z-index:3;
  }

  .close-container {
    position: relative;
  }

  .close-button {
    position: absolute;
    color: #fff;
    right: -104px;
    padding: 1.5rem;
    width: 80px;

    i {
      font-size: 2rem;
      margin-left: 4px;
    }
  }

}

.vert-green {

}

.bb-green {
  border-bottom: 0.5rem solid #C9DD03;
  margin-top: 4rem;
}
.row {
  width: 100%;
}

.form--area {
  overflow: auto;
  max-height: 94%;
  padding: 0;
}

.icon--button {
  width:38px;
  height:38px;
  text-align: center;
  vertical-align: center;
}

.form--row {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #ddd;
  padding-bottom: 1rem;
  position: relative;

  legend {
    font-weight:600;
    line-height:2;

  }
  label {
    font-weight:600;

  }

  input[type=range] {
    -webkit-appearance: none;
    width: 80%;

  }

  .range-view {
    position: absolute;
    right: 4px;
    top: 2px;
  }

}
.tooltip.show {
  opacity: 1;
}


.btn-default {
  color: $headings-color;
  background-color: #fff;
  border-color: #ccc;
}

.ml-20 {
  margin-left: 16rem !important;
}

.craue_formflow_buttons {
  overflow: hidden;
}

#profile_lookup_form {
  span.twitter-typeahead {
    display: block !important;

  }

  .form-group {
    margin: 0;

  }

  .col-auto {
    width: 2rem;
  }

  .col {
    max-width: 75%;
  }
}
.form-control-borderless {
  border: none;
}

.form-control-borderless:hover, .form-control-borderless:active, .form-control-borderless:focus {
  border: none;
  outline: none;
  box-shadow: none;
}
.header {

}
.custom-page--tab-headline {
  color: $headings-color;
  font-family: 'GLSWebLight';
  font-weight: 100;
  line-height: 1.3;
  font-size: 1.5rem;
  margin-bottom: 1.5625rem;

  a {
    color: $headings-color;
  }
}
.label--select {
  width: 100%;

  label {
    background-color: #eee;
    padding: .5rem .75rem;
    margin-right: 0.5rem;
    width: 48.5%;
    &.active {
      background-color: $primary;
      color: #fff;
    }

    input {
      display: none;
    }
  }
}

.datetime_widget.flex {
  display:flex;

  select {
    padding-right: 0;
    padding-left: 0.375rem;
  }
}


.flex-label--select {
  width: 100%;
  display: flex;

  label {
    background-color: #eee;
    padding: .5rem .75rem;
    margin-right: 0.5rem;
    flex:1;
    &.active {
      background-color: $primary;
      color: #fff;
    }

    input {
      display: none;
    }
  }
}

.btn {
  &.is--active {
    background-color: #8DAA0B;
    border-color: #8DAA0B;
    color: #fff;
  }
}

@media (min-width: 768px) {
  body {
    font-size: 0.8rem;
  }
  h3 {
    font-size: 1rem;
  }
  h4 {
    font-size: 0.9rem;
  }
  .left-info {
    font-size: 0.8rem;
    position: absolute;
    left: 2%;
    bottom: -50rem;
    width: 27%;
    opacity: 0;
    padding-left: 1rem !important;
    padding-right: 1rem !important;

    .info-body {
      padding: 24px;
      background-color: #fde800;
    }
  }
  .header {
    padding-top: 2rem;
    border-bottom: 0.5rem solid #C9DD03;
    padding-bottom: 1.5rem;
    padding-right: 1rem;
    margin-bottom: 1.5rem;
  }
  .form-control {
      font-size: 0.8rem;
  }
  .custom-page--tab-headline {
    color: $headings-color;
    font-family: 'GLSWebLight';
    font-weight: 100;
    line-height: 1.3;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    a {
      color: $headings-color;
    }
  }
  .intro {
    font-size: 0.9rem;
    padding: 1.5rem !important;
    margin-bottom: 1rem !important;
  }
  .btn {
    font-size: 0.7rem;
  }

  .button-bar {
    padding: 1rem;
    .btn {
      font-size: 0.8rem;
      padding-left: 1rem;
    }
    i {
      font-size: 1rem;
      margin-right: 1rem !important;
    }
  }

  .label--select {
    width: 100%;
    font-size: 0.8rem;
    label {
      background-color: #eee;
      padding: .5rem .75rem;
      margin-right: 0.5rem;
      width: 48.0%;
      &.active {
        background-color: $primary;
        color: #fff;
      }

      input {
        display: none;
      }
    }
  }

  .datetime_widget.flex {
    display:flex;

    select {
      padding-right: 0;
      padding-left: 0.375rem;
    }
  }

  .form--row {
    font-size: 0.8rem;
  }

  .flex-label--select {
    width: 100%;
    display: flex;
    font-size: 0.8rem;
    label {
      background-color: #eee;
      padding: .5rem .75rem;
      margin-right: 0.5rem;
      flex:1;

      &.active {
        background-color: $primary;
        color: #fff;
      }

      input {
        display: none;
      }
    }
  }
}

@media (min-width: 1200px) {
  body {
    font-size: 1.0rem;
  }
  h3 {
    font-size: 1.5rem;
  }
  h4 {
    font-size: 1.15rem;
  }
  .left-info {
    position: absolute;
    left: 2%;
    bottom: -50rem;
    width: 27%;
    opacity: 0;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    .info-body {
      padding: 24px;
      background-color: #fde800;
    }
  }
  .header {
    padding-top: 3rem !important;
    border-bottom: 0.5rem solid #C9DD03;
    padding-bottom: 1.5rem !important;
    padding-right: 1rem !important;
    margin-bottom: 1.5rem !important;

    .custom-page--tab-headline {
      color: $headings-color;
      font-family: 'GLSWebLight';
      font-weight: 100;
      line-height: 1.3;
      font-size: 2.5rem;
      margin-bottom: 1rem;

      a {
        color: $headings-color;
      }
    }
  }
  .form-control {
    font-size: 1rem;
  }
  .btn {
    font-size: 1rem;
  }
  .intro {
    padding: 1.5rem !important;
    font-size: 1rem;
  }

  .button-bar {
    .btn {
      font-size: 1rem;
      padding-left: 1.5rem;
    }
    i {
      font-size: 1.5rem;
      margin-right: 1.5rem !important;
    }
  }

  .label--select {
    width: 100%;
    font-size: 1rem;
    label {
      background-color: #eee;
      padding: .5rem .75rem;
      margin-right: 0.5rem;
      width: 48.5%;
      &.active {
        background-color: $primary;
        color: #fff;
      }

      input {
        display: none;
      }
    }
  }

  .datetime_widget.flex {
    display:flex;

    select {
      padding-right: 0;
      padding-left: 0.375rem;
    }
  }

  .form--row {
    font-size: 1rem;
  }

  .flex-label--select {
    width: 100%;
    display: flex;

    label {
      background-color: #eee;
      padding: .5rem .75rem;
      margin-right: 0.5rem;
      flex:1;
      &.active {
        background-color: $primary;
        color: #fff;
      }

      input {
        display: none;
      }
    }
  }

  a.accessBackLink:link{color: black}
  a.accessBackLink:visited{color: black}
  a.accessBackLink:hover{text-decoration: none}
}
