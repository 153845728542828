

.top-bar {

  nav.top-bar--navigation {
    position: relative;
    display: block;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        float: left;
        margin-right: 0.5rem;

      }
    }
  }

}