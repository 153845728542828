#sequence {
    width: 680px;
    height: 70px;
    text {
        font-weight: 600;
        fill: $white;
    }
}

#chart {
    position: relative;
    path {
        stroke: $white;
        stroke-width: 2;
    }
}

#chart_capital {
    position: relative;
    path {
        stroke: $white;
        stroke-width: 2;
    }
}

#explanation {
    position: absolute;
    top: 260px;
    left: 250px;
    width: 250px;
    text-align: center;
    color: $greyShark;
    z-index: -1;
}

#percentage {
    font-size: 2em;
}

