/*! CSS Used from: https://www.gls-laden.de/web/cache/1524819383_28a027bcc5c7175b2a750110841ba1df.css */

header, nav {
  display: block;
}

.navigation--list {
  list-style: none;
}

.header-main {
  padding-bottom: 10px;

  background: #fff;
  border-bottom: 1px solid #54565c;
  min-height: 160px;
  .logo-main {
    padding: 10px 10px 10px 10px;

    .logo--link {

      height: 2.1875rem;
      display: inline-block;
      max-width: 100%;
    }
    .logo--shop {

      height: 2.1875rem;
      width: 50%;
    }
    .logo--supportinfo {
      display: none;
    }
  }

}

.header--navigation {
  position: relative;
  display: inline-block;
}

@media screen and (min-width: 48em) {
  .header-main {
    padding-bottom: 0;
    border-bottom: 0 none;
    .container:after {
      content: "";
      display: table;
      clear: both;
    }
    .top-bar {
      padding: 4px 0px 4px 0px;

      display: block;
    }
    .top-bar--navigation {
      width: auto;
      float: right;
    }
    .header--navigation {
      padding: 10px 0px 10px 0px;

    }
    .logo-main {
      padding: 0px 0px 0px 10px;

      width: 35%;
      .logo--link {
        height: 50px;

      }
      .logo--shop {
        height: 50px;

        width: 82%;
      }
    }
  }
  .logo-main {
    float: left;
  }
}

@media screen and (min-width: 64em) {
  .header-main {
    .logo-main {
      width: 30%;
    }

  }

}

@media screen and (min-width: 78.75em) {
  .header-main {
    .top-bar {
      margin-bottom: 2px;

    }
    .logo--shop {
      width: 75%;
    }
  }

}

@media screen and (min-width: 48em) {
  .header-main {
    padding-bottom: 0;
    border-bottom: 0 none;
    padding-top: 10px;
    padding-top: .625rem;
    .container:after {
      content: "";
      display: table;
      clear: both;
    }
    .top-bar {
      padding: 4px 0px 4px 0px;
      padding: .25rem 0rem .25rem 0rem;
      display: block;
    }
    .top-bar--navigation {
      width: auto;
      float: right;
      padding-right: 12px;
      padding-right: .75rem;
    }
  }
}