* {
  box-sizing: border-box;
}

body {
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

/* Navigation */
.navigation {
  position: absolute;
  width: 100%;
  height: 100px;
  padding: 0 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
}

/* Slider wrapper*/
.css-slider-wrapper {
  display: block;
  background: #FFF;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

/* Slider */
.slider {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 1;
  z-index: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  align-content: center;
  -webkit-transition: -webkit-transform 1600ms;
  transition: -webkit-transform 1600ms, transform 1600ms;
  -webkit-transform: scale(1);
  transform: scale(1);
}

/* Slides Background Color */
.slide-1 {
  background: #fff;
  left: 0;
}
.slide-2 {
  background: #fff;
  left: 100%
}
.slide-3 {
  background: #fff;
  left: 200%
}
.slide-4 {
  background: #fff;
  left: 300%;
}
.slide-5 {
  background: #fff;
  left: 100%;
}
.slider {
  display: flex;
  justify-content: flex-start;
}
.slider-content {
  width: 635px;
  padding-left: 100px;
}


/* Slider Inner Slide Effect */
.slider h2 {
  color: #333333;
  font-weight: 900;
  text-transform: capitalize;
  font-size: 60px;
  font-weight: 300;
  line-height: 1.2;
  opacity: 0;
  -webkit-transform: translateX(500px);
  transform: translateX(500px);
  margin-top: 0;
  letter-spacing: 2px;
}

.slider h4 {
  font-size: 22px;
  font-family: "GLSWebLight";
  color: rgba(51, 51, 51, 0.349);
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.2;
  opacity: 0;
  -webkit-transform: translateX(500px);
  transform: translateX(500px);
}

.slider > img {
  position: absolute;
  right: 10%;
  bottom: 0;
  height: 100%;
  opacity: 0;
  -webkit-transform: translateX(500px);
  transform: translateX(500px);
}

.slider > .next-slide {
  position: absolute;
  bottom: 80px;
  right: 800px;
  font-family: "GLSWebLight";
  font-weight: bold;
  opacity: 0;
  -webkit-transform: translateX(500px);
  transform: translateX(500px);
}

.slide-1 > img,
.slide-2 > img,
.slide-3 > img,
.slide-4 > img,
.slide-5 > img
{
  right: 0;
}

.slider .buy-now-btn:focus,
.navigation .login-btn:focus {
  outline: none;
}

/* Animations */
.slider h2 {
  -webkit-transition: opacity 800ms, -webkit-transform 800ms;
  transition: transform 800ms, opacity 800ms;
  -webkit-transition-delay: 1s; /* Safari */
  transition-delay: 1s;
}
.slider h4 {
  -webkit-transition: opacity 800ms, -webkit-transform 800ms;
  transition: transform 800ms, opacity 800ms;
  -webkit-transition-delay: 1.4s; /* Safari */
  transition-delay: 1.4s;
}
.slider > img {
  -webkit-transition: opacity 800ms, -webkit-transform 800ms;
  transition: transform 800ms, opacity 800ms;
  -webkit-transition-delay: 1.2s; /* Safari */
  transition-delay: 1.2s;
}
.slider > .next-slide {
  -webkit-transition: opacity 800ms, -webkit-transform 800ms;
  transition: transform 800ms, opacity 800ms;
  -webkit-transition-delay: 0.8s; /* Safari */
  transition-delay: 0.8s;
}


/* Number Pagination */
.number-pagination {
  position: absolute;
  bottom: 70px;
  right: 100px;
  font-family: "GLSWebLight";
  font-weight: bold;
}

.number-pagination span {
  font-size: 30px;
  color: #58A618;
  letter-spacing: 4px;
}

.number-pagination span:after {
  content: "/2";
  font-size: 16px;
  color: #58A618;
}

/* Slider Pagger */
.slider-pagination {
  position: absolute;
  bottom: 90px;
  width: 475px;
  left: 100px;
  z-index: 1000;
  display: flex;
  align-items: center;
}
.slider-pagination label {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  background: #333333;
  margin: 0 10px;
  cursor: pointer;
}

/* Slider Pagger Event */
.slide-radio1:checked ~ .slider-pagination .page1,
.slide-radio2:checked ~ .slider-pagination .page2,
.slide-radio3:checked ~ .slider-pagination .page3,
.slide-radio4:checked ~ .slider-pagination .page4,
.slide-radio5:checked ~ .slider-pagination .page5
{
  width: 14px;
  height: 14px;
  border: 2px solid #58A618;
  background: transparent;
}

/* Slider Slide Effect */
.slide-radio1:checked ~ .slider {
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
}
.slide-radio2:checked ~ .slider {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
.slide-radio3:checked ~ .slider {
  -webkit-transform: translateX(-200%);
  transform: translateX(-200%);
}
.slide-radio4:checked ~ .slider {
  -webkit-transform: translateX(-300%);
  transform: translateX(-300%);
}
.slide-radio5:checked ~ .slider {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.slide-radio1:checked ~ .slide-1 h2,
.slide-radio2:checked ~ .slide-2 h2,
.slide-radio3:checked ~ .slide-3 h2,
.slide-radio4:checked ~ .slide-4 h2,
.slide-radio5:checked ~ .slide-5 h2,
.slide-radio1:checked ~ .slide-1 h4,
.slide-radio2:checked ~ .slide-2 h4,
.slide-radio3:checked ~ .slide-3 h4,
.slide-radio4:checked ~ .slide-4 h4,
.slide-radio5:checked ~ .slide-5 h4,
.slide-radio1:checked ~ .slide-1 > .next-slide,
.slide-radio2:checked ~ .slide-2 > .next-slide,
.slide-radio3:checked ~ .slide-3 > .next-slide,
.slide-radio4:checked ~ .slide-4 > .next-slide,
.slide-radio5:checked ~ .slide-5 > .next-slide,
.slide-radio1:checked ~ .slide-1 > img,
.slide-radio2:checked ~ .slide-2 > img,
.slide-radio3:checked ~ .slide-3 > img,
.slide-radio4:checked ~ .slide-4 > img,
.slide-radio5:checked ~ .slide-5 > img {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  opacity: 1
}

/* Responsive */
@media only screen and (max-width: 768px) {
  .slider h2 {
    font-size: 20px;
  }
  .slider h4 {
    font-size: 16px;
  }

  .slider-content {
    padding: 0 2%
  }

  .navigation {
    padding: 0 2%;
  }

  .navigation-left {
    display: none;
  }

  .number-pagination {
    right: 2%;
  }
  .slider-pagination {
    left: 2%;
  }

  .slider > img {
    right: 2%;
  }

  .slide-1 > img {
    right: -110px;
  }
}
