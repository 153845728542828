// Overrideable variables
$checkbox-border: 1px solid $secondary !default;
$checkbox-radius: 2px !default;
$checkbox-size: 2rem !default;
$checkbox-font-size: 1.75rem !default;
$checkbox-offset-top: 0.4rem !default;
$checkbox-bg: none !default;
$checkbox-label-indent: 1rem !default;
$checkbox-fa: "\f00c" !default;
$checkbox-fa-size: $checkbox-size * 0.9 !default;
$checkbox-fa-margin: calc(
  #{($checkbox-size - $checkbox-fa-size) * 0.5} + 1px
) !default;
$checkbox-fa-color: $primary !default;
$checkbox-cursor: pointer !default;
$checkbox-error-color: #c00 !default;

$radio-border: $checkbox-border !default;
$radio-radius: 50% !default;
$radio-size: $checkbox-size !default;
$radio-offset-top: $checkbox-offset-top !default;
$radio-bg: $checkbox-bg !default;
$radio-label-indent: $checkbox-label-indent !default;
$radio-fa: "\f111" !default;
$radio-fa-size: $radio-size * 0.7 !default;
$radio-fa-margin: calc(#{($radio-size - $radio-fa-size) * 0.5} + 2px) !default;
$radio-fa-color: $checkbox-fa-color !default;
$radio-cursor: $checkbox-cursor !default;
$radio-error-color: $checkbox-error-color !default;

@mixin checkbox-or-radio(
  $border,
  $radius,
  $size,
  $offset-top,
  $label-indent,
  $bg,
  $cursor,
  $fa,
  $fa-size,
  $fa-margin,
  $fa-color,
  $error-color
) {
  position: absolute;
  left: -9999px;
  // + label {
  // to handle sandwiched validation elements
  ~ label {
    &:hover {
      background-color: $gray-100;
    }
    &.odd {
      background-color: $gray-100;
    }
    position: relative;
    display: block;
    padding-left: $size + $label-indent;
    font-weight: normal;
    text-transform: none;
    cursor: $cursor;
    font-size: $checkbox-font-size;
    user-select: none;
    &::before,
    &::after {
      position: absolute;
      top: $offset-top;
      left: 0;
      display: block;
      content: "";

    }
    &::before {
      width: $size;
      height: $size;
      vertical-align: middle;
      background: $bg;
      border: $border;
      border-radius: $radius;
    }
    &::after {
      margin: $fa-margin;
      font: normal normal normal 14px/1 'Font Awesome 5 Free';
      font-weight: 900;

      font-size: $fa-size;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      line-height: 1;
      color: $fa-color;
      text-rendering: auto;
      opacity: 0.2;
      transform: translate(5px, -10px);
      transition: All ease-in-out 0.2s;
    }
    h3 {
      display: block;
    }
  }
  &:checked ~ label::after {
    content: $fa;
    transform: translate(0px,0px);
    transition: All ease-in-out 0.2s;
    opacity: 1;
  }
  &.error ~ label::before {
    border-color: $error-color;
  }
}
.document-radio,
.document-checkbox {
  display: block;

}
.document-checkbox input[type="checkbox"] {
  @include checkbox-or-radio(
    $border: $checkbox-border,
    $radius: $checkbox-radius,
    $size: $checkbox-size,
    $offset-top: $checkbox-offset-top,
    $bg: $checkbox-bg,
    $label-indent: $checkbox-label-indent,
    $cursor: $checkbox-cursor,
    $fa: $checkbox-fa,
    $fa-size: $checkbox-fa-size,
    $fa-margin: $checkbox-fa-margin,
    $fa-color: $checkbox-fa-color,
    $error-color: $checkbox-error-color
  );
}
.document-radio input[type="radio"] {
  // Same as a checkbox but, well, round.
  @include checkbox-or-radio(
    $border: $radio-border,
    $radius: $radio-radius,
    $size: $radio-size,
    $offset-top: $radio-offset-top,
    $bg: $radio-bg,
    $label-indent: $radio-label-indent,
    $cursor: $radio-cursor,
    $fa: $radio-fa,
    $fa-size: $radio-fa-size,
    $fa-margin: $radio-fa-margin,
    $fa-color: $radio-fa-color,
    $error-color: $radio-error-color
  );
}
