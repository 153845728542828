.footer-main {


  display: block;
  background-repeat: repeat-x;
  border-top-width: 0px;
  a {

    color: rgb(84, 86, 92);

  }
  .inner {
    .line--ico {
      width: 100%;
      float: left;
    }
    .line {
      margin-bottom: 0.3125rem;
      color: rgb(84, 86, 92);
      display: block;
      font-family: GLSWebLight;
      &.is-first {
        margin-bottom: 0.625rem;
      }
    }
  }
  .inner {
    max-width: 60rem;
    padding-top: 1.75rem;
    padding-right: 0rem;
    padding-bottom: 0rem;
    padding-left: 0rem;
    margin-top: 0px;
    margin-right: auto;
    margin-bottom: 0px;
    margin-left: auto;
    width: 100%;
    .footer--columns {
      border-bottom-width: 0px;
      border-top-width: 0px;

      .footer--column {
        padding-top: 0rem;
        padding-right: 0.625rem;
        padding-bottom: 0rem;
        padding-left: 0.625rem;
        border-top-width: 1px;
        border-top-style: solid;
        border-top-color: rgb(225, 229, 235);
        width: 100%;
        border-bottom-width: 0px;
        border-bottom-style: initial;
        border-bottom-color: initial;

      }

    }

    .column--headline {
      user-select: none;
      padding-top: 0rem;
      padding-right: 0rem;
      padding-bottom: 0.5rem;
      padding-left: 1rem;
      margin-top: 0px;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: 0px;
      font-weight: normal;
      color: rgb(84, 86, 92);
      cursor: default;
      font-size: 1.125rem;
      line-height: 1.625rem;
      font-family: GLSWebLight;
      &::after {
        font-size: 1.125rem;
        font-family: shopware;
        color: rgb(84, 86, 92);
        font-weight: 700;
        content: "";
        float: right;
      }
    }
  }
}

.footer-main .inner {
  .contact {
    padding-top: 0rem;
    padding-right: 0.625rem;
    padding-bottom: 0rem;
    padding-left: 0.625rem;
    font-size: 14px;
    font-family: GLSWebSemiBold;
    color: rgb(84, 86, 92);
    display: inline-block;
    width: 100%;
    line-height: 28px;
    &.is-first {
      padding-left: 0.625rem;
    }
  }
  .icon {
    display: inline-block;
    width: 1.5625rem;
  }
}

.footer-main {
  .inner .line {
    strong {
      font-family: GLSWebSemiBold;
      font-weight: normal;
    }
    em {
      font-style: normal;
      padding-left: 0.25rem;
    }
  }
  .footer--column.is--last {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgb(225, 229, 235);
  }
  .inner .footer--columns .footer--column.is--last {
    display: none;
  }
}

.footer-main {
  .inner {
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      li {
        float: left;
        display: list-item;
        margin-top: 0rem;
        margin-right: 0.625rem;
        margin-bottom: 0rem;
        margin-left: 0rem;
        font-size: 0.75rem;
        position: relative;
        color: rgb(255, 255, 255);
        a {
          color: rgb(255, 255, 255);
        }
      }
    }
    .tooltip {
      display: none;
      bottom: 120px;
      left: -80px;
      opacity: 0;
      position: absolute;
      font-style: normal;
      font-weight: normal;
      width: 232px;
      z-index: 9999;
      &::after {
        content: "";
        display: table;
        clear: both;
      }
    }
  }
}
